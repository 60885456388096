<template>
  <div class="w-full">
    <div v-if="accountId" class="mt-2">
      <account-balance :accountId="accountId" />
    </div>

    <div class="my-2 flex-between w-full">
      <d-tabs :tabs="tabs" v-model="tab" />
      <div class="flex-center space-x-2">
        <template v-if="tab.key !== 4">
          <account-filter
            v-if="!hideFilter"
            :key="key"
            @onChange="(selected) => (accountIds = selected)"
          />
          <logic-filter
            v-if="!accountId"
            :key="key + 1"
            @onChange="(selected) => (logicIds = selected)"
          />
          <button
            v-if="!hideFilter"
            class="ml-auto btn-xs-primary"
            @click.stop="_loadData"
          >
            {{ $t('Apply') }}
          </button>
        </template>
        <button
          v-if="hideFilter || accountId || filtered || selectedIds.length > 0"
          class="ml-auto btn-xs-warning"
          @click.stop="_reset"
        >
          {{ $t('Reset') }}
        </button>
        <template v-if="tab.key !== 4">
          <button
            v-if="selectedIds.length > 0"
            class="ml-auto btn-xs-primary"
            @click.stop="_cancelOrders"
          >
            {{ $t('Clear') }}
          </button>
          <button
            v-if="selectedIds.length > 0"
            class="ml-auto btn-xs-warning"
            @click.stop="_closeOrders"
          >
            {{ $t('Revert') }}
          </button>
        </template>
      </div>
    </div>

    <div class="mt-4 flex flex-col" v-if="tab.key !== 4">
      <d-paging
        v-if="totalPage > 1"
        class="ml-auto"
        size="xs"
        v-model="page"
        :total="totalPage"
      />
      <div class="overflow-x-scroll">
        <d-table
          :key="tableKey"
          :tick-able="tab.key === 1"
          class="my-4"
          :headers="headers"
          :items="orders"
          compact
          wrapperClasses="overflow-y-scroll"
          @onChecked="onChecked"
        />
      </div>
    </div>
    <div v-if="tab.key === 4">
      <my-bot
        :key="key + 3"
        :_accountIds="accountIds"
        :_selectedIds="selectedIds"
        :showFilter="false"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import headerGenerator from './headers.js'
import MyBot from '@/views/my-bots/index.vue'
import AccountBalance from './AccountBalance.vue'

const tabs = [
  {
    key: 1,
    title: 'Open',
  },
  {
    key: 2,
    title: 'Close',
  },
  {
    key: 3,
    title: 'Active',
  },
  {
    key: 4,
    title: 'Bots',
  },
]

export default {
  name: 'Orders',

  meta: {
    title: 'Orders',
  },

  components: {
    MyBot,
    AccountBalance,
  },

  data: () => ({
    hideFilter: false,
    key: 1,
    tableKey: 1,
    selectedIds: [],
    isMobile: global.IS_MOBILE,
    accountIds: [],
    logicIds: [],
    orders: [],
    page: 1,
    totalPage: 0,
    tabs,
    tab: tabs[0],
    accountId: null,
    filtered: false,
  }),

  computed: {
    ...mapGetters({
      logics: 'logic/logics',
      accountById: 'credentials/accountMap',
      logicById: 'logic/logicById',
      marketPrices: 'market/marketPrices',
    }),
    isActive() {
      return this.tab.key === 3
    },
    headers() {
      return headerGenerator(this)
    },
  },

  watch: {
    tab() {
      this.page = 1
      this._loadData()
    },
    page() {
      this._loadData()
    },
  },

  async mounted() {
    if (localStorage.___accountId) {
      this.hideFilter = true
      this.accountId = localStorage.___accountId
      this.accountIds = [this.accountId]
    } else if (localStorage.___accountIds) {
      this.hideFilter = true
      this.accountIds = localStorage.___accountIds.split(',')
    }
    delete localStorage.___accountId
    delete localStorage.___accountIds
    await this._loadData()
  },
  methods: {
    onChecked(ids) {
      this.selectedIds = ids
    },
    _reset() {
      this.hideFilter = false
      this.key = Date.now()
      this.selectedIds = []
      this.accountIds = []
      this.logicIds = []
      this.page = 1
      this.accountId = null
      this._loadData()
    },
    _cancelOrders() {
      this.$$confirm({
        title: 'Notice',
        text: 'Do you want to cancel selected orders?',
        confirmText: 'Yes',
        confirmColor: 'warning',
        onConfirm: async () => {
          this.$store.commit('common/INCREASE_COUNTER')
          await this.$http.post('/api/v2/logic-orders/cancel', {
            ids: this.selectedIds,
          })
          this.selectedIds = []
          await this._loadData()
          this.$store.commit('common/DECREASE_COUNTER')
        },
      })
    },
    _closeOrders() {
      this.$$confirm({
        title: 'Notice',
        text: 'Do you want to close selected orders?',
        confirmText: 'Yes',
        confirmColor: 'warning',
        onConfirm: async () => {
          this.$store.commit('common/INCREASE_COUNTER')
          await this.$http.post('/api/v2/logic-orders/close', {
            ids: this.selectedIds,
          })
          this.selectedIds = []
          await this._loadData()
          this.$store.commit('common/DECREASE_COUNTER')
        },
      })
    },
    async _loadData() {
      const { docs, lastPage } = await this.$http.post('/api/v2/logic-orders', {
        accountIds: this.accountId ? [this.accountId] : this.accountIds,
        logicIds: this.logicIds,
        page: this.page,
        type: this.tab.key,
        limit: 15,
      })
      this.orders = docs
      this.totalPage = lastPage
      this.filtered = this.accountIds.length > 0 || this.logicIds.length > 0
      this.tableKey++
    },
  },
}
</script>
